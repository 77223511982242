import React from "react";
import ProgramWrap from "../../ProgramWrap";
import { Badge, Button, ListGroup } from "react-bootstrap";
import SearchBox from "../../../ui/SearchBox";
import PaginationFromHeader from "../../../ui/PaginationFromHeader";
import { BsMegaphoneFill, BsX } from "react-icons/bs";
import ImgBox from "../../../ui/ImgBox";
import { FaStore } from "react-icons/fa6";
import CycleIcon from "../../../custom/CycleIcon";

const ProgramListGroup = ({ selectedProgram, setSelectedProgram }) => {
  return (
    <ProgramWrap
      selectedProgram={selectedProgram}
      setSelectedProgram={setSelectedProgram}
      component={({
        programs,
        handleClick,
        handleClear,
        page,
        setPage,
        PAGE_SIZE,
        searchQuery,
        setSearchQuery,
        headers,
      }) => (
        <>
          <div className="d-flex justify-content-between">
            <SearchBox
              placeholder="Search Programs"
              query={searchQuery}
              setQuery={setSearchQuery}
              maxWidth={300}
              size="md"
              clearSearchStyle={{
                position: "absolute",
                zIndex: "999",
                top: "7px",
                left: "225px",
                color: "red",
              }}
            />

            {!!selectedProgram && (
              <Button variant="danger" onClick={handleClear}>
                <BsX />
              </Button>
            )}
          </div>
          <ListGroup
            className="scrollbar-thin"
            style={{
              "--bs-list-group-active-bg": "#4b626b",
              "--bs-list-group-active-border-color": "#4b626b",
              maxHeight: 350,
              marginTop: 10,
              overflowY: "auto",
              paddingTop: 10,
              cursor: "pointer",
            }}
          >
            {!!programs && programs.length > 0
              ? programs.map((program) => (
                  <ListGroup.Item
                    active={selectedProgram?.id === program.id}
                    key={program.id}
                    action
                    onClick={() => handleClick(program)}
                  >
                    <div
                      className="d-flex justify-content-between w-100"
                      style={{ paddingLeft: 3, paddingRight: 3 }}
                    >
                      <div className="w-100 d-flex align-items-center">
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                          }}
                          className="border-end me-2 overflow-hidden d-flex align-items-center"
                        >
                          <ImgBox
                            className="d-flex align-items-center"
                            imgId={program?.logoFileId}
                            width={48}
                            icon={FaStore}
                            imgBg="secondary"
                            resizeMode="contain"
                            style={{ with: 48, height: "100%" }}
                          />
                        </div>
                        <div className="lh-sm" style={{ fontSize: "15px" }}>
                          <div className="fw-semibold">{program.name}</div>
                          <small className="text-muted font-monospace">
                            <Badge
                              style={{
                                backgroundColor: "#6b598b",
                                color: "white",
                              }}
                              className={`${
                                selectedProgram?.id === program.id
                                  ? ""
                                  : "hover-opacity-0"
                              }text-light text-opacity-75 font-monospace me-2 ms-n1`}
                            >
                              {program.id}
                            </Badge>
                          </small>
                        </div>
                      </div>
                      <div className="w-100 d-flex justify-content-end">
                        <span
                          className={`${
                            selectedProgram?.id === program.id
                              ? ""
                              : "hover-opacity-0"
                          }font-monospace small opacity-75 d-flex align-items-center`}
                        >
                          {program.programType === "cycle" ? (
                            <div className="d-flex align-items-center">
                              <CycleIcon style={{ marginRight: 5 }} />{" "}
                              {" Cycle"}
                            </div>
                          ) : program.programType === "campaign" ? (
                            <div className="d-flex align-items-center">
                              <BsMegaphoneFill style={{ marginRight: 5 }} />{" "}
                              {" Campaign"}
                            </div>
                          ) : (
                            program.programType
                          )}
                        </span>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))
              : null}
          </ListGroup>
          {!!headers?.link ? (
            <div
              className="d-flex justify-content-center"
              style={{ paddingTop: 10 }}
            >
              <PaginationFromHeader
                current={page}
                linkStr={headers.link}
                setPage={setPage}
                showButtons
                pageSize={PAGE_SIZE}
              />
            </div>
          ) : null}
        </>
      )}
    />
  );
};

export default ProgramListGroup;

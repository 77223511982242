import React from "react";
import { Button, ButtonGroup, Stack } from "react-bootstrap";
import { FaStore } from "react-icons/fa6";
import useFilters from "../../context/StorageContext";

const FilterButtons = () => {
  const { customer, setCustomer } = useFilters();

  return (
    <ButtonGroup
      as={Stack}
      direction="horizontal"
      gap={2}
      className="me-3 filter-btns"
      style={{
        "--bs-border-radius": 0,
        "--bs-border-radius-lg": 0,
      }}
    >
      {/* <Button
        variant="outline-secondary"
        title="Campaign"
        className="colored-btn campaign fade-in-grow"
      >
        <BsMegaphoneFill />
      </Button> */}
      {/* <Button
        variant="outline-secondary"
        title="Cycle"
        className="colored-btn cycle fade-in-grow"
      >
        <CycleIcon />
      </Button> */}
      <Button
        variant="outline-secondary"
        title={customer?.name || customer?.id || "No customer selected"}
        className="colored-btn customer fade-in-grow"
        active={!!customer?.id}
        onClick={() => {
          setCustomer(null);
        }}
      >
        <FaStore />
      </Button>
    </ButtonGroup>
  );
};

export default FilterButtons;

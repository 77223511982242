import React from "react";
import { FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsSearch, BsX } from "react-icons/bs";

const SearchBox = ({
  query,
  setQuery,
  className = "",
  placeholder = "Search",
  icon: Icon = BsSearch,
  maxWidth,
  style,
  clearSearchStyle,
  ...props
}) => {
  const handleInput = (e) => {
    if (e.code === "Space") {
      e.stopPropagation();
    }
    if (e.code === "Escape" && !!query) {
      e.stopPropagation();
      setQuery("");
    }
  };

  return (
    <div className={`${className} position-relative`}>
      {/* Search input */}
      <FormControl
        type="text"
        placeholder={placeholder}
        aria-label="Search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleInput}
        style={{
          paddingLeft: "35px",
          paddingRight: "30px",
          maxWidth: maxWidth || "200px", // Adjust to your desired max width
          width: "100%",
        }} // Padding to avoid text overlap with icons
        {...props}
      />

      {/* Non-clickable floating search icon */}
      <div
        style={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: "999", // Adjusted zIndex value
        }}
      >
        <Icon size={16} />
      </div>

      {/* Clear input button */}
      {query && (
        <OverlayTrigger overlay={<Tooltip>Clear search</Tooltip>}>
          <span
            style={
              clearSearchStyle || {
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "red",
                zIndex: "999", // Adjusted zIndex value
              }
            }
          >
            <BsX size={16} onClick={() => setQuery("")} />
          </span>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default SearchBox;

/*import React from "react";
import {
  FormControl,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { BsSearch, BsX } from "react-icons/bs";

const SearchBox = ({
  query,
  setQuery,
  className = "",
  placeholder = "Search",
  icon: Icon = BsSearch,
  ...props
}) => {
  const handleInput = (e) => {
    if (e.code === "Escape" && !!query) {
      e.stopPropagation();
      setQuery("");
    }
  };

  return (
    <InputGroup className={`${className} position-relative`}>
      <Icon
        style={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: "2",
        }}
        size={16}
      />
      <FormControl
        type="text"
        placeholder={placeholder}
        aria-label="Search help section"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleInput}
        style={{ paddingLeft: "40px" }}
        {...props}
      />

      {query && (
        <OverlayTrigger overlay={<Tooltip>Clear search</Tooltip>}>
          <InputGroup.Text
            style={{
              cursor: "pointer",
              color: "red",
            }}
            onClick={() => setQuery("")}
          >
            <BsX />
          </InputGroup.Text>
        </OverlayTrigger>
      )}
    </InputGroup>
  );
};

export default SearchBox;*/

import React, { useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { BsX } from "react-icons/bs";

const CountButton = ({
  count,
  children,
  successBg = "success",
  dangerBg = "danger-tint-10",
  defaultBg = "secondary-tint-25",
  onClear = () => {},
  ...rest
}) => {
  const [hoverBadge, setHoverBadge] = useState(false);
  return (
    <Button
      size="sm"
      variant="outline-secondary"
      className="position-relative"
      {...rest}
    >
      {children}
      <Badge
        pill
        bg={count > 0 ? (hoverBadge ? dangerBg : successBg) : defaultBg}
        className={`position-absolute start-100 mt-1 translate-middle transition-bg ${
          count > 0
            ? "cursor-pointer"
            : "cursor-default pointer-events-none opacity-0"
        }`}
        title={count > 0 ? "Clear" : ""}
        onMouseEnter={() => count > 0 && setHoverBadge(true)}
        onMouseLeave={() => setHoverBadge(false)}
        style={
          hoverBadge
            ? {
                paddingLeft: ".4em",
                paddingRight: ".4em",
              }
            : {}
        }
        onClick={onClear || (() => {})}
      >
        {hoverBadge ? <BsX /> : count}
      </Badge>
    </Button>
  );
};

export default CountButton;

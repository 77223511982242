import { useEffect, useState } from "react";
import { config } from "../../config";
import useFetch from "../../utils/useFetch";

const TreatmentsWrap = ({
  setSelectedTreatments,
  selectedTreatments,
  component: Component,
}) => {
  const { api } = config;
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 30;
  const [searchQuery, setSearchQuery] = useState("");

  const { data: treatments, headers } = useFetch({
    url: `${api.phoenix}/treatments?page=${page}&pageSize=${PAGE_SIZE}&query=${searchQuery}`,
  });

  const handleClick = (treatment) => {
    if (selectedTreatments.some((t) => t.id === treatment.id)) {
      setSelectedTreatments(
        selectedTreatments.filter((t) => t.id !== treatment.id)
      );
    } else {
      setSelectedTreatments([...selectedTreatments, treatment]);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  return (
    <Component
      treatments={treatments}
      handleClick={handleClick}
      page={page}
      setPage={setPage}
      PAGE_SIZE={PAGE_SIZE}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      headers={headers}
      handleClear={() => setSelectedTreatments([])}
    />
  );
};
export default TreatmentsWrap;

import React from "react";
import { config } from "../../config";
import ImgBox from "../ui/ImgBox";
import ListPage from "../ui/ListPage";
import { Dropdown, ButtonGroup, Card, Stack } from "react-bootstrap";
import { BsCheck2, BsFunnelFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaStore } from "react-icons/fa6";
import CustomerView from "./CustomerDetail/CustomerView";
import CustomerListItem, {
  CustomerListHeader,
} from "./CustomerDetail/CustomerListItem";
import AddNewCustomer from "./CustomerDetail/AddNewCustomer";
import useStorage from "../../context/StorageContext";
import useListPage from "../../utils/useListPage";

const Customers = () => {
  const [state, setValue] = useListPage("customers");

  const { activeFilter = "" } = state;
  const setActiveFilter = (val) => setValue({ activeFilter: val, page: 1 });

  // const [activeFilter, setActiveFilter] = useState("");

  const ACTIVE_FILTER_OPTIONS = ["active", "inactive"];

  const ActiveFilterSelect = () => {
    return (
      <Dropdown as={ButtonGroup} className="filter-menu">
        <Dropdown.Toggle
          variant="outline-secondary"
          size="sm"
          active={!!activeFilter}
          title="filter"
        >
          <BsFunnelFill />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {ACTIVE_FILTER_OPTIONS.map((option) => (
            <Dropdown.Item
              as="span"
              className="cursor-pointer"
              onClick={() =>
                // setActiveFilter((curr) => (curr === option ? "" : option))
                setActiveFilter(activeFilter === option ? "" : option)
              }
              active={activeFilter === option}
              key={option}
            >
              <BsCheck2
                className={`${
                  activeFilter !== option ? "opacity-0" : ""
                } text-success me-2`}
              />
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const { setCustomer } = useStorage();

  const GridItem = ({ item }) => {
    return (
      <Link
        className="text-decoration-none hover-parent d-flex align-items-center h-100 d-block"
        to={`/customers/${item.id}`}
        onClick={() =>
          setCustomer({
            id: item?.id,
            name: item?.name,
            logoFileId: item?.logoFileId,
          })
        }
      >
        <Card className="d-flex border-0 h-100 justify-content-between thumbnail-card fade-in-grow-up delay w-100 ">
          <Card.Body className="p-0 w-100 flex-grow-0">
            <ImgBox
              imgId={item?.logoFileId}
              width={200}
              padding={32}
              className="fade-in-grow delay d-flex align-items-center h-100"
              icon={FaStore}
              imgBg="white"
              imgColor="rgba(var(--bs-px-dark-rgb), .2)"
              resizeMode="contain"
              style={{
                "--img-box-aspect-ratio": "50%",
              }}
            />
          </Card.Body>
          <Card.Footer className="py-2 border-top-0 bg-gray-200 flex-grow-1 d-flex flex-column justify-content-around">
            <Card.Title as="h6" className="mb-1 text-center">
              {item.name}
            </Card.Title>
            <Stack
              direction="horizontal"
              gap={3}
              className="justify-content-around"
            >
              {/* <span className="font-monospace text-secondary opacity-75 fs-75">
                {item.code}
              </span> */}
              <span className="text-secondary opacity-75 fs-75">
                {item.city}
              </span>
            </Stack>
          </Card.Footer>
        </Card>
      </Link>
    );
  };

  return (
    <ListPage
      baseUrl={`${config.api.phoenix}/customers`}
      urlParams={`&filter=${activeFilter}`}
      itemLabel="customer"
      itemPath="customers/view"
      orderOptions={["id", "code", "name"]}
      searchProps={{
        title: "Name, description, code, ID",
      }}
      newItemBtn={<AddNewCustomer />}
      gridItem={GridItem}
      viewItem={CustomerView}
      listItem={CustomerListItem}
      listHeader={CustomerListHeader}
      thumbnailCol="logoFileId"
      tableItems={{
        logoFileId: {
          // displayHeading: " ",
          cellType: "thumbnail",
          cellPadding: "0px",
          cellWidth: 56,
          component: ({ value }) => (
            <ImgBox
              imgId={value}
              width={54}
              height={32}
              ratio={false}
              padding={2}
              className="img-box-aspect-ratio-65"
              icon={FaStore}
              imgBg="rgba(255,255,255,.7)"
              imgColor="rgba(var(--bs-px-dark-rgb), .2)"
              resizeMode="contain"
            />
          ),
        },
        id: {},
        code: {
          cellClass: "text-start",
        },
        name: {},
        city: {
          cellType: "text",
        },
      }}
      headerItems={<ActiveFilterSelect />}
      layoutOptions={["list", "table", "grid"]}
      defaultLayout="grid"
      menuType="customers"
      colorClass="customer"
      storageKey="customers"
      filters={
        <div>
          Active | Inactive | All <hr /> more filters...
        </div>
      }
    />
  );
};

export default Customers;

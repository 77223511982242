import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  FormSelect,
  InputGroup,
  Modal,
  OverlayTrigger,
  Stack,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useAuthContext } from "../../../context/AuthContext";
import {
  BsArrowDownRight,
  BsArrowLeftRight,
  BsArrowUpRight,
  BsMegaphoneFill,
} from "react-icons/bs";
import { config } from "../../../config";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import CycleIcon from "../../custom/CycleIcon";
import CreateVariantModal from "./CreateVariantModal";
import fetcher from "../../../utils/fetcher";
import ImgBox from "../../ui/ImgBox";
import { FaStore } from "react-icons/fa6";
import PaginationButtons from "../../ui/PaginationButtons";
import SearchBox from "../../ui/SearchBox";
import NoResults from "../../ui/NoResults";

const AddVariantManually = ({
  setShowProfileModal,
  showDrawer,
  setShowDrawer,
  setCurrentCustomer,
  showMultiCustomerDrawer,
  setShowMultiCustomer,
  linkToOrder = false,
  showOpenManual,
  setShowOpenManual,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [programs, setPrograms] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const orderProgramOptions = ["id", "sequence", "slider", "theme"];
  const directionOptions = ["ascending", "descending"];

  const [orderBy, setOrderBy] = useState(orderProgramOptions[0]);
  const [direction, setDirection] = useState(directionOptions[0]);

  const [page, setPage] = useState(1);
  const PAGE_SIZE = 30;

  const { client } = useAuthContext();

  useEffect(() => {
    if (!client?.customer) return;
    setIsLoading(true);
    fetcher({
      method: "GET",
      url: `${config.api.phoenix}/programs?customerId=${client?.customer?.id}&page=${page}&pageSize=${PAGE_SIZE}&query=${searchQuery}&direction=${direction}&orderBy=${orderBy}`,
      customerId: client?.customer?.id,
      clientId: client?.id,
    })
      .then((res) => {
        linkToOrder
          ? setPrograms(
              res.data.filter((item) => item.programType === "campaign")
            )
          : setPrograms(res.data);
      })
      .catch((err) => setError(err))
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    client.id,
    client.customer,
    page,
    PAGE_SIZE,
    searchQuery,
    orderBy,
    direction,
    linkToOrder,
  ]);

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const handleUseSelectedProgram = () => {
    setShowMenu(true);
  };

  const handleCancelProgramModal = () => {
    setSelectedProgram({});
    setShowOpenManual(false);
    setShowProfileModal(false);
  };

  return (
    <>
      {linkToOrder ? (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              {
                "This creates a Variant and links it to an already existing Order. (Recommended)"
              }
            </Tooltip>
          }
        >
          <Button
            variant="transparent"
            style={{ color: "white" }}
            className="w-100 d-flex justify-content-centert p-1"
            onClick={() => {
              setShowOpenManual(true);
            }}
          >
            <span> {"Create linked to Order"}</span>
          </Button>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              This creates a Variant manually without linking it to Order.
            </Tooltip>
          }
        >
          <Button
            variant="transparent"
            style={{ color: "white" }}
            className="w-100 d-flex justify-content-centert p-1"
            onClick={() => {
              setShowOpenManual(true);
            }}
          >
            <span>Create manually</span>
          </Button>
        </OverlayTrigger>
      )}

      <Modal
        centered
        show={showOpenManual}
        onHide={handleCancelProgramModal}
        style={
          showDrawer || showMultiCustomerDrawer || showMenu
            ? {
                zIndex: 1030,
              }
            : {}
        }
        backdrop={!showDrawer}
        className="h-100"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Select a Program to create Variant"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: 450 }}>
          <div className="d-flex justify-content-between">
            <div>
              {!!client?.customer && (
                <div
                  className="d-flex"
                  style={{ marginLeft: 12, paddingBottom: 4 }}
                >
                  <div style={{ width: "48px" }}>
                    <ImgBox
                      className="d-flex align-items-center"
                      imgId={client?.customer?.logoFileId}
                      width={48}
                      icon={FaStore}
                      imgBg="secondary"
                      resizeMode="contain"
                      style={{ width: 48, height: "100%" }}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-between w-100 align-items-center"
                    style={{ paddingLeft: 5 }}
                  >
                    <div className="lh-sm">
                      <div className="fw-semibold">
                        {client?.customer?.name}
                      </div>
                      <small className="text-muted font-monospace">
                        <Badge
                          style={{
                            backgroundColor: "hsla(45, 100.00%, 40.00%, 0.85)",
                          }}
                        >
                          {client?.customer?.id}
                        </Badge>
                      </small>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <p>
                  <Button
                    variant="link"
                    onClick={() => {
                      setCurrentCustomer(null);
                      setShowDrawer(true);
                    }}
                  >
                    {"Switch Customer"} <BsArrowLeftRight />
                  </Button>
                </p>
              </div>
            </div>
            <div style={{ padding: 5 }}>
              <SearchBox
                placeholder="Search Program on name"
                maxWidth={350}
                className="w-100"
                query={searchQuery}
                setQuery={setSearchQuery}
                clearSearchStyle={{
                  position: "absolute",
                  zIndex: "999",
                  top: "7px",
                  left: "220px",
                  color: "red",
                }}
              />
            </div>
          </div>

          <WithLoaderAndError isLoading={isLoading} error={error}>
            <div style={{ maxHeight: 300, overflowY: "auto" }}>
              {Array.isArray(programs) && !!programs && programs.length > 0 ? (
                <Table striped hover>
                  <thead className="sticky-top">
                    <tr>
                      <th>Program Name</th>
                      <th>Program Title</th>
                      <th>Program Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {programs.map((program) => (
                      <tr
                        key={program.id}
                        onClick={() => setSelectedProgram(program)}
                        style={{ cursor: "pointer" }}
                      >
                        <td
                          style={
                            selectedProgram?.id === program.id
                              ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                              : {}
                          }
                        >
                          {program.name}
                        </td>
                        <td
                          style={
                            selectedProgram?.id === program.id
                              ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                              : {}
                          }
                        >
                          {program.title}
                        </td>
                        <td
                          style={
                            selectedProgram?.id === program.id
                              ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                              : {}
                          }
                        >
                          {program.programType === "cycle" ? (
                            <div className="d-flex align-items-center">
                              <CycleIcon style={{ marginRight: 5 }} />{" "}
                              {" Cycle"}
                            </div>
                          ) : program.programType === "campaign" ? (
                            <div className="d-flex align-items-center">
                              <BsMegaphoneFill style={{ marginRight: 5 }} />{" "}
                              {" Campaign"}
                            </div>
                          ) : (
                            program.programType
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="w-100 d-flex justify-content-center align-items-center no-results-wrap">
                  <NoResults img="tumbleweed" animation="tumbleweed" />
                </div>
              )}
            </div>
          </WithLoaderAndError>
        </Modal.Body>
        <Modal.Footer className="w-100">
          <div className="d-flex justify-content-between w-100">
            <div>
              <Stack gap={2} direction="horizontal" className="w-auto">
                <span className="small">Sort by</span>
                <InputGroup size="sm" className="w-auto align-items-center">
                  <FormSelect
                    aria-label="Sort by"
                    defaultValue={orderBy}
                    onChange={(e) => setOrderBy(e.target.value)}
                  >
                    {orderProgramOptions.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </FormSelect>
                  <Button
                    variant="light"
                    onClick={() =>
                      setDirection((curr) =>
                        curr === "ascending" ? "descending" : "ascending"
                      )
                    }
                    title={direction}
                  >
                    {direction === "ascending" ? (
                      <BsArrowUpRight title={"ascending"} />
                    ) : (
                      <BsArrowDownRight title={"descending"} />
                    )}
                  </Button>
                </InputGroup>
              </Stack>
            </div>
            <div>
              <PaginationButtons
                page={page}
                setPage={setPage}
                pageSize={PAGE_SIZE}
                isLoading={isLoading}
              />
            </div>
            <div>
              <Button
                variant="secondary"
                onClick={handleCancelProgramModal}
                style={{ marginRight: 3 }}
              >
                Cancel
              </Button>
              <Button
                variant="success"
                onClick={handleUseSelectedProgram}
                disabled={!selectedProgram}
              >
                Use Selected
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {!!selectedProgram ? (
        <CreateVariantModal
          show={showMenu}
          setShowMenu={setShowMenu}
          program={selectedProgram}
          customer={client?.customer}
          onGoBackToProgramSelect={() => {
            setShowOpenManual(true);
          }}
          setShowProfileModal={setShowProfileModal}
          setShowMultiCustomer={setShowMultiCustomer}
          showMultiCustomerDrawer={showMultiCustomerDrawer}
          linkToOrder={linkToOrder}
        />
      ) : null}
    </>
  );
};

export default AddVariantManually;

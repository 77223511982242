import React from "react";
// import { BsBriefcaseFill } from "react-icons/bs";
import FulfilmentPartnerWrap from "./FulfilmentPartnerWrap";
import { Card, Stack } from "react-bootstrap";
import ImgBox from "../../ui/ImgBox";
import { BsBuildingFill } from "react-icons/bs";

const FulfilmentPartnerCard = ({ id, customerId }) => {
  return id ? (
    <FulfilmentPartnerWrap
      id={id}
      customerId={customerId}
      component={({ name, email, logo }) => (
        <Card bg="white" className="border-0 p-3">
          <Stack direction="horizontal">
            <ImgBox
              imgId={logo}
              width={35}
              className="fade-in delay flex-grow-0 ms-1 me-3"
              icon={BsBuildingFill}
              imgBg="white"
              imgColor="rgba(var(--bs-px-dark-rgb), .2)"
              resizeMode="contain"
              style={{ flexBasis: 35 }}
            />
            <div className="flex-grow-1">
              <h5 className="fw-semibold mb-0">{name}</h5>
              {email && <div className="fs-80 text-muted">{email}</div>}
            </div>
          </Stack>
        </Card>
      )}
    />
  ) : null;
};

export default FulfilmentPartnerCard;

import { useState } from "react";
import useStorage from "../context/StorageContext";

// accepts
// storage key string: storageKey + "Store" = store exported from StorageContext
// if no context store exists, a new state object is created outside of context
//
// returns an array containing
// 1. state object
// 2. setValue: accepts an object with new values overwrite the state object
// 3. setState: react setState funciton

const useListPage = (storageKey) => {
  const allStorage = useStorage();
  const fallBackStore = useState({});
  const [state, setState] = allStorage?.[`${storageKey}Store`] || fallBackStore;
  const setValue = (obj) => setState((curr) => ({ ...curr, ...obj }));
  return [state, setValue, setState];
};

export default useListPage;

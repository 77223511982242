import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {
  CgChevronRight,
  CgPushChevronRight,
  CgChevronLeft,
  CgPushChevronLeft,
} from "react-icons/cg";
import parseLinkHeader from "../../utils/parseLinkHeader";
import useTotals from "../../utils/useTotals";

const PaginationFromHeader = ({
  current,
  linkStr,
  variant = "outline-secondary",
  size = "sm",
  setPage,
  showButtons = true,
  pageSize,
}) => {
  const links = parseLinkHeader(linkStr);
  const { firstItem, lastItem } = useTotals({
    linkStr,
    page: current,
    pageSize,
  });

  const PageBtn = ({
    page,
    isDisabled = false,
    label,
    icon: Icon,
    className,
  }) => {
    const [hovered, setHovered] = useState(false);
    return (
      <Button
        variant={variant}
        disabled={isDisabled}
        onClick={() => setPage(+page)}
        title={label}
        size={size}
        className={className}
        onMouseEnter={(e) => setHovered(true)}
        onMouseLeave={(e) => setHovered(false)}
      >
        <span
          className="text-center d-inline-block"
          style={{ minWidth: "1em" }}
        >
          {hovered ? page : <Icon />}
        </span>
      </Button>
    );
  };

  return (
    <div>
      {showButtons ? (
        <PageBtn
          page={links?.first?.page}
          isDisabled={!links?.first}
          label="First"
          icon={CgPushChevronLeft}
        />
      ) : null}
      {showButtons ? (
        <PageBtn
          page={links?.prev?.page}
          isDisabled={!links?.first}
          label="Previous"
          icon={CgChevronLeft}
          className="mx-1"
        />
      ) : null}

      <span
        className={`btn cursor-default ${
          size === "sm" ? "btn-sm" : size === "lg" ? "btn-lg" : ""
        }`}
        title={`${firstItem} - ${lastItem}`}
      >
        {current}
        {/* {`${current} of ${!!links?.last?.page ? links?.last?.page : current}`} */}
      </span>
      {showButtons ? (
        <PageBtn
          page={links?.next?.page}
          isDisabled={!links?.next}
          label="Next"
          icon={CgChevronRight}
          className="mx-1"
        />
      ) : null}
      {showButtons ? (
        <PageBtn
          page={links?.last?.page}
          isDisabled={!links?.last}
          label="Last"
          icon={CgPushChevronRight}
        />
      ) : null}
    </div>
  );
};

export default PaginationFromHeader;

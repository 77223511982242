import { Badge, Button, ListGroup } from "react-bootstrap";
import SearchBox from "../../../ui/SearchBox";
import CustomersWrap from "../../CustomersWrap";
import { BsX } from "react-icons/bs";
import PaginationFromHeader from "../../../ui/PaginationFromHeader";
import ImgBox from "../../../ui/ImgBox";
import { FaStore } from "react-icons/fa6";

const CustomersListGroup = ({ selectedCustomers, setSelectedCustomers }) => {
  return (
    <CustomersWrap
      selectedCustomers={selectedCustomers}
      setSelectedCustomers={setSelectedCustomers}
      component={({
        customers,
        handleClick,
        page,
        setPage,
        PAGE_SIZE,
        searchQuery,
        setSearchQuery,
        headers,
        handleClear,
      }) => (
        <>
          <div className="d-flex justify-content-between">
            <SearchBox
              placeholder="Search Customers"
              query={searchQuery}
              setQuery={setSearchQuery}
              maxWidth={300}
              size="md"
              clearSearchStyle={{
                position: "absolute",
                zIndex: "999",
                top: "7px",
                left: "225px",
                color: "red",
              }}
            />
            {!!selectedCustomers && selectedCustomers.length > 0 && (
              <Button variant="danger" onClick={handleClear}>
                <BsX />
              </Button>
            )}
          </div>
          <ListGroup
            className="scrollbar-thin"
            style={{
              "--bs-list-group-active-bg": "#4b626b",
              "--bs-list-group-active-border-color": "#4b626b",
              maxHeight: 350,
              marginTop: 10,
              overflowY: "auto",
              paddingTop: 10,
              cursor: "pointer",
            }}
          >
            {!!customers && customers.length > 0
              ? customers.map((customer) => (
                  <ListGroup.Item
                    key={customer.id}
                    active={selectedCustomers.some(
                      (selected) => selected.id === customer.id
                    )}
                    onClick={() => handleClick(customer)}
                  >
                    <div
                      className="d-flex justify-content-between w-100"
                      style={{ paddingLeft: 3, paddingRight: 3 }}
                    >
                      <div className="w-100 d-flex align-items-center">
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                          }}
                          className="border-end me-2 overflow-hidden d-flex align-items-center"
                        >
                          <ImgBox
                            className="d-flex align-items-center"
                            imgId={customer?.logoFileId}
                            width={48}
                            icon={FaStore}
                            imgBg="secondary"
                            resizeMode="contain"
                            style={{ with: 48, height: "100%" }}
                          />
                        </div>
                        <div className="lh-sm" style={{ fontSize: "15px" }}>
                          <div className="fw-semibold">{customer.name}</div>
                          <small className="text-muted font-monospace">
                            <Badge
                              style={{
                                backgroundColor:
                                  "hsla(45, 100.00%, 40.00%, 0.85)",
                                color: "white",
                              }}
                              className={`${
                                selectedCustomers.some(
                                  (selected) => selected.id === customer.id
                                )
                                  ? ""
                                  : "hover-opacity-0"
                              }text-light text-opacity-75 font-monospace me-2 ms-n1`}
                            >
                              {customer.id}
                            </Badge>
                          </small>
                        </div>
                      </div>
                      <div className="w-100 d-flex justify-content-end">
                        <span
                          className={`${
                            selectedCustomers.some(
                              (selected) => selected.id === customer.id
                            )
                              ? ""
                              : "hover-opacity-0"
                          }font-monospace small opacity-75 d-flex align-items-center`}
                        >
                          {customer.code}
                        </span>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))
              : null}
          </ListGroup>
          {!!headers?.link ? (
            <div
              className="d-flex justify-content-center"
              style={{ paddingTop: 10 }}
            >
              <PaginationFromHeader
                current={page}
                linkStr={headers.link}
                setPage={setPage}
                showButtons
                pageSize={PAGE_SIZE}
              />
            </div>
          ) : null}
        </>
      )}
    />
  );
};

export default CustomersListGroup;

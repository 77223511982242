import { useEffect, useState } from "react";
import { config } from "../../config";
import useFetch from "../../utils/useFetch";

const CustomersWrap = ({
  selectedCustomers,
  setSelectedCustomers,
  component: Component,
}) => {
  const { api } = config;

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 30;

  const { data: customers, headers } = useFetch({
    url: `${api.phoenix}/customers?page=${page}&pageSize=${PAGE_SIZE}&query=${searchQuery}`,
  });

  const handleClick = (customer) => {
    if (selectedCustomers.some((c) => c.id === customer.id)) {
      setSelectedCustomers(
        selectedCustomers.filter((c) => c.id !== customer.id)
      );
    } else {
      setSelectedCustomers([...selectedCustomers, customer]);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  return (
    <Component
      customers={customers}
      handleClick={handleClick}
      page={page}
      setPage={setPage}
      PAGE_SIZE={PAGE_SIZE}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      headers={headers}
      handleClear={() => setSelectedCustomers([])}
    />
  );
};

export default CustomersWrap;

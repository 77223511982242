import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";

const VariantFilterWrap = ({ component: Component }) => {
  const [show, setShow] = useState(false);
  const { client, setClient } = useAuthContext();
  const [selectedCustomers, setSelectedCustomers] = useState(
    !!client?.customers ? client.customers : []
  );
  const [selectedProgram, setSelectedProgram] = useState(
    !!client?.program ? client.program : null
  );
  const [selectedTreatments, setSelectedTreatments] = useState(
    !!client?.treatments ? client.treatments : []
  );

  const [selectedMailMoment, setSelectedMailMoment] = useState(
    !!client?.mailMoment ? client.mailMoment : null
  );

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    if (!client?.program) {
      setSelectedProgram(null);
    }
    if (!client?.treatments) {
      setSelectedTreatments([]);
    }
    if (!client?.mailMoment) {
      setSelectedMailMoment(null);
    }
    if (!client?.customers) {
      setSelectedCustomers([]);
    }
  };

  const handleApply = () => {
    setShow(false);
    if (!!selectedProgram) {
      setClient((prev) => ({
        ...prev,
        program: selectedProgram,
      }));
    }
    if (!!selectedTreatments && selectedTreatments.length > 0) {
      setClient((prev) => ({
        ...prev,
        treatments: selectedTreatments,
      }));
    }
    if (!!selectedMailMoment) {
      setClient((prev) => ({
        ...prev,
        mailMoment: selectedMailMoment,
      }));
    }
    if (!!selectedCustomers && selectedCustomers.length > 0) {
      setClient((prev) => ({
        ...prev,
        customers: selectedCustomers,
      }));
    }
  };

  const handleClear = () => {
    if (!!client?.program) {
      setClient((prev) => ({
        ...prev,
        program: null,
      }));
      setSelectedProgram(null);
    }
    if (!!client?.treatments && client.treatments.length > 0) {
      setClient((prev) => ({
        ...prev,
        treatments: [],
      }));
      setSelectedTreatments([]);
    }
    if (!!client?.mailMoment) {
      setClient((prev) => ({
        ...prev,
        mailMoment: null,
      }));
      setSelectedMailMoment(null);
    }
    if (!!client?.customers && client.customers.length > 0) {
      setClient((prev) => ({
        ...prev,
        customers: [],
      }));
      setSelectedCustomers([]);
    }
  };

  useEffect(() => {
    if (selectedProgram === null) {
      setSelectedMailMoment(null);
    }
  }, [selectedProgram]);

  return (
    <Component
      client={client}
      show={show}
      handleOpen={handleOpen}
      handleClose={handleClose}
      selectedCustomers={selectedCustomers}
      setSelectedCustomers={setSelectedCustomers}
      selectedProgram={selectedProgram}
      setSelectedProgram={setSelectedProgram}
      selectedTreatments={selectedTreatments}
      setSelectedTreatments={setSelectedTreatments}
      selectedMailMoment={selectedMailMoment}
      setSelectedMailMoment={setSelectedMailMoment}
      handleApply={handleApply}
      handleClear={handleClear}
    />
  );
};

export default VariantFilterWrap;

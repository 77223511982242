import React from "react";
import { Offcanvas, Button, Accordion, ButtonGroup } from "react-bootstrap";
import VariantFilterWrap from "../../VariantFilterWrap";
import { BsFunnelFill, BsX } from "react-icons/bs";
import TreatmentsListGroup from "./TreatmentsListGroup";
import MailMomentListGroup from "./MailMomentListGroup";
import CustomersListGroup from "./CustomersListGroup";
import ProgramListGroup from "./ProgramListGroup";

const VariantFilterMenu = () => {
  return (
    <VariantFilterWrap
      component={({
        client,
        show,
        handleOpen,
        handleClose,
        selectedCustomers,
        setSelectedCustomers,
        selectedProgram,
        setSelectedProgram,
        selectedTreatments,
        setSelectedTreatments,
        selectedMailMoment,
        setSelectedMailMoment,
        handleApply,
        handleClear,
      }) => {
        return (
          <>
            <ButtonGroup size="sm">
              <Button
                size="sm"
                variant={
                  !!client?.program ||
                  (!!client?.treatments && client.treatments.length > 0) ||
                  !!client?.mailMoment ||
                  (!!client?.customers && client.customers.length > 0)
                    ? "secondary"
                    : "outline-secondary"
                }
                onClick={handleOpen}
              >
                <BsFunnelFill style={{ marginRight: 5 }} /> Filter Variants
              </Button>
              {(!!client?.program ||
                (!!client?.treatments && client.treatments.length > 0) ||
                !!client?.mailMoment ||
                (!!client?.customers && client.customers.length > 0)) && (
                <Button variant="danger" onClick={handleClear}>
                  <BsX />
                </Button>
              )}
            </ButtonGroup>

            <Offcanvas
              show={show}
              onHide={handleClose}
              style={{ width: "700px" }}
              data-bs-theme="dark"
            >
              <Offcanvas.Header closeButton closeVariant="white">
                <Offcanvas.Title>Filter Menu</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body
                className="d-flex flex-column"
                style={{ overflow: "hidden", maxHeight: "100dvh" }}
              >
                <Accordion
                  className="flex-grow-1 overflow-auto"
                  style={{
                    "--bs-accordion-active-bg": "rgba(75, 98, 107, 0.15)",
                    "--bs-accordion-active-color": "white",
                    "--bs-accordion-btn-icon": "white",

                    "--bs-accordion-btn-focus-border-color": "none",
                    "--bs-accordion-btn-focus-box-shadow": "none",

                    overflowY: "auto",
                    height: "90%",
                  }}
                >
                  {/* CUSTOMERS */}

                  <Accordion.Item
                    className="w-100"
                    eventKey="0"
                    style={{
                      borderLeft:
                        selectedCustomers?.length > 0
                          ? "4px solid #00994d"
                          : "4px solid transparent",
                    }}
                  >
                    <Accordion.Header className="w-100">
                      Customers
                    </Accordion.Header>
                    <Accordion.Body
                      className="d-flex flex-column"
                      style={{
                        backgroundColor: "rgba(75, 98, 107, 0.02)",
                        maxHeight: "60vh",
                        overflow: "hidden",
                      }}
                    >
                      <CustomersListGroup
                        selectedCustomers={selectedCustomers}
                        setSelectedCustomers={setSelectedCustomers}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* PROGRAMS */}
                  <Accordion.Item
                    eventKey="1"
                    style={{
                      borderLeft: !!selectedProgram
                        ? "4px solid #00994d"
                        : "4px solid transparent",
                    }}
                  >
                    <Accordion.Header>Programs</Accordion.Header>
                    <Accordion.Body
                      className="d-flex flex-column"
                      style={{
                        backgroundColor: "rgba(75, 98, 107, 0.02)",
                        maxHeight: "60vh",
                        overflow: "hidden",
                      }}
                    >
                      <ProgramListGroup
                        selectedProgram={selectedProgram}
                        setSelectedProgram={setSelectedProgram}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* TREATMENTS */}
                  <Accordion.Item
                    eventKey="2"
                    style={{
                      borderLeft:
                        selectedTreatments?.length > 0
                          ? "4px solid #00994d"
                          : "4px solid transparent",
                    }}
                  >
                    <Accordion.Header>Treatments</Accordion.Header>
                    <Accordion.Body
                      className="d-flex flex-column"
                      style={{
                        backgroundColor: "rgba(75, 98, 107, 0.02)",
                        maxHeight: "60vh",
                        overflow: "hidden",
                      }}
                    >
                      <TreatmentsListGroup
                        selectedTreatments={selectedTreatments}
                        setSelectedTreatments={setSelectedTreatments}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* MAIL MOMENTS */}
                  <Accordion.Item
                    eventKey="3"
                    style={{
                      borderLeft: !!selectedMailMoment
                        ? "4px solid #00994d"
                        : "4px solid transparent",
                    }}
                  >
                    <Accordion.Header>Mail Moments</Accordion.Header>
                    <Accordion.Body
                      className="d-flex flex-column"
                      style={{
                        backgroundColor: "rgba(75, 98, 107, 0.02)",
                        maxHeight: "60vh",
                        overflow: "hidden",
                      }}
                    >
                      {selectedProgram ? (
                        <MailMomentListGroup
                          selectedProgram={selectedProgram}
                          selectedMailMoment={selectedMailMoment}
                          setSelectedMailMoment={setSelectedMailMoment}
                        />
                      ) : (
                        <div
                          style={{ height: "100px" }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          First select a Program
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <div className="d-flex justify-content-end mt-3">
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="me-2"
                  >
                    Close
                  </Button>
                  <Button
                    variant="success"
                    onClick={handleApply}
                    disabled={
                      selectedProgram === null &&
                      selectedTreatments.length === 0 &&
                      selectedCustomers.length === 0 &&
                      selectedMailMoment === null
                    }
                  >
                    Apply
                  </Button>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </>
        );
      }}
    />
  );
};

export default VariantFilterMenu;

import axios from "axios";

const fetcher = async ({
  url,
  baseURL,
  params = {},
  method = "GET",
  customerId,
  clientId,
  payload = null,
  withCredentials = true,
  headers: customHeaders = {},
} = {}) => {
  const { data, headers } = await axios({
    method: method,
    url: url,
    baseURL,
    params,
    data: payload,
    withCredentials: withCredentials,
    headers: {
      ...customHeaders,
      pxclient: clientId ? clientId : null,
      pxcustomer: customerId ? customerId : null,
    },
  });
  return { data, headers };
};

export default fetcher;

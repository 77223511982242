import { useEffect, useState } from "react";
import useFetch from "../../../utils/useFetch";
import { config } from "../../../config";
import { Button, Modal, Table } from "react-bootstrap";
import Moment from "react-moment";
import WithLoaderAndError from "../../ui/WithLoaderAndError";

const CreateVariantSearchOrderModal = ({
  show,
  setShow,
  setOrderInfo,
  treatmentId,
  programId,
  customerId,
}) => {
  const [orderViewModels, setOrderViewModels] = useState([]);

  const {
    data: orders,
    isValidating: orderIsLoading,
    error: ordersError,
  } = useFetch({
    //first fetch the orders based on the right program and customer
    url: `${config.api.order}/orders?customerId=${customerId}&programId=${programId}&pageSize=50`,
  });

  const {
    data: products,
    isValidating: productsIsLoading,
    error: productsError,
  } = useFetch({
    url: `${config.api.order}/products?programId=${programId}&treatmentId=${treatmentId}&pageSize=50`,
  });

  useEffect(() => {
    if (!orders || orders.length === 0 || !products || products.length === 0)
      return;

    const models = [];

    orders.forEach((order) => {
      const productItems = [
        ...(order.productItems || []),
        ...(order.packageItems || []).flatMap(
          (packageItem) => packageItem.products || []
        ),
      ];

      const product = products[0]; //Within productItems we need to search for the first product fetched in products

      const productSet = new Set(); //can have multiple products with the same id in 1 order but still need the one within the right packageItem ord productItem set

      productItems.forEach((item) => {
        if (product.id === item.productId) {
          if (item.isCustom || product.treatmentId === treatmentId) {
            //we need the one with the right treatmentId of one that has isCustom = true
            productSet.add(product);
          }
        }
      });

      const newProduct = Array.from(productSet);

      const matchedProduct = newProduct[0]; //undo array

      if (!matchedProduct) return; //no product found

      models.push({
        id: order.id,
        programId: order.program.id,
        programName: order.program.name,
        participationDate: order.participationDate,
        orderDate: order.orderDate,
        customerCode: order.customer.code,
        customerName: order.customer.name,
        customerId: order.customer.id,
        modified: order.modified,
        productName: matchedProduct.name,
        productId: matchedProduct.id,
        productCode: matchedProduct.code,
      });
    });
    setOrderViewModels(models);
  }, [orders, products, treatmentId]);

  const handleRowClick = (item) => {
    setOrderInfo(item);
    setShow(false);
  };

  return (
    <Modal
      centered
      size="xl"
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Link to Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WithLoaderAndError
          isLoading={orderIsLoading || productsIsLoading}
          error={ordersError || productsError}
        >
          {" "}
          <div style={{ maxHeight: "450px", overflowY: "auto" }}>
            {Object.keys(orderViewModels).length > 0 ? (
              <Table striped hover>
                <thead>
                  <tr>
                    <th>id</th>
                    <th>Participation date</th>
                    <th>Order date</th>
                    <th>Customer code</th>
                    <th>Customer name</th>
                    <th>Program name</th>
                    <th>Modified</th>
                    <th>Product name</th>
                  </tr>
                </thead>
                <tbody>
                  {orderViewModels?.map((item) => (
                    <tr key={item.id} onClick={() => handleRowClick(item)}>
                      <td>{item.id}</td>
                      <td>
                        {item.participationDate ? (
                          <Moment format="DD MMM YYYY HH:mm">
                            {item.participationDate}
                          </Moment>
                        ) : null}
                      </td>
                      <td>
                        {item.orderDate ? (
                          <Moment format="DD MMM YYYY HH:mm">
                            {item.orderDate}
                          </Moment>
                        ) : null}
                      </td>
                      <td>{item.customerCode}</td>
                      <td>{item.customerName}</td>
                      <td>{item.programName}</td>
                      <td>
                        <Moment format="DD MMM YYYY HH:mm">
                          {item.modified}
                        </Moment>
                      </td>
                      <td>{item.productName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="w-100 d-flex alignt-items-center justify-content-center">
                {"No order can be linked to this variant"}
              </div>
            )}
          </div>
        </WithLoaderAndError>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateVariantSearchOrderModal;

import React, { useState, useContext } from "react";

export const DrawerContext = React.createContext();
export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }) => {
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [showSelectClient, setShowSelectClient] = useState(false);
  const [query, setQuery] = useState("");

  const handleShowOffCanvas = () => {
    setShowOffCanvas(true);
  };
  const handleCloseOffCanvas = () => setShowOffCanvas(false);
  const handleLookup = (q) => {
    setQuery(q);
    handleShowOffCanvas();
  };

  return (
    <DrawerContext.Provider
      value={{
        showOffCanvas,
        setShowOffCanvas,
        handleShowOffCanvas,
        handleCloseOffCanvas,

        query,
        setQuery,
        showSelectClient,
        setShowSelectClient,

        handleLookup,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

import { Button, ListGroup } from "react-bootstrap";
import MailMomentsWrap from "../../MailMomentsWrap";
import { BsX } from "react-icons/bs";

const MailMomentListGroup = ({
  selectedProgram,
  selectedMailMoment,
  setSelectedMailMoment,
}) => {
  return (
    <MailMomentsWrap
      selectedProgram={selectedProgram}
      selectedMailMoment={selectedMailMoment}
      setSelectedMailMoment={setSelectedMailMoment}
      component={({ mailMoments, handleClick, handleClear }) => (
        <>
          <div className="d-flex justify-content-end">
            {!!selectedMailMoment && (
              <Button variant="danger" onClick={handleClear}>
                <BsX />
              </Button>
            )}
          </div>
          <ListGroup
            className="scrollbar-thin"
            style={{
              "--bs-list-group-active-bg": "#4b626b",
              "--bs-list-group-active-border-color": "#4b626b",
              maxHeight: 350,
              marginTop: 10,
              overflowY: "auto",
              paddingTop: 10,
              cursor: "pointer",
            }}
          >
            {!!mailMoments && mailMoments.length > 0
              ? mailMoments.map((mailMoment) => (
                  <ListGroup.Item
                    active={selectedMailMoment?.id === mailMoment.id}
                    key={mailMoment.id}
                    action
                    onClick={() => handleClick(mailMoment)}
                  >
                    {mailMoment.name}
                  </ListGroup.Item>
                ))
              : null}
          </ListGroup>
        </>
      )}
    />
  );
};

export default MailMomentListGroup;

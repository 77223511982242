import React from "react";
import { Image } from "react-bootstrap";
import embarrassed from "../../assets/il-embarrassed.svg";
import dizzy from "../../assets/il-dizzy.svg";
import drunk from "../../assets/il-drunk.svg";
import tumbleweed from "../../assets/tumbleweed.svg";

const NoResults = ({
  img = "drunk",
  children,
  animation = "float",
  showTumbleweed = true,
}) => {
  return (
    <div
      className={`no-results m-auto text-center fade-in no-results ${
        animation === "tumbleweed" ? "tumbleweed-wrapper" : ""
      }`}
    >
      <Image
        className={animation}
        src={img === "drunk" ? drunk : img === "dizzy" ? dizzy : embarrassed}
      />
      {children ? (
        children
      ) : (
        <div className="text-muted mt-2">No results found</div>
      )}
      {showTumbleweed ? (
        <div className="tumbleweed-wrapper">
          <Image className="tumbleweed" src={tumbleweed} />
        </div>
      ) : null}
    </div>
  );
};

export default NoResults;

import { BsX } from "react-icons/bs";
import SearchBox from "../../../ui/SearchBox";
import TreatmentsWrap from "../../TreatmentsWrap";
import { Button, ListGroup } from "react-bootstrap";
import PaginationFromHeader from "../../../ui/PaginationFromHeader";

const TreatmentsListGroup = ({ selectedTreatments, setSelectedTreatments }) => {
  return (
    <TreatmentsWrap
      selectedTreatments={selectedTreatments}
      setSelectedTreatments={setSelectedTreatments}
      component={({
        treatments,
        handleClick,
        page,
        setPage,
        PAGE_SIZE,
        searchQuery,
        setSearchQuery,
        headers,
        handleClear,
      }) => (
        <>
          <div className="d-flex justify-content-between">
            <SearchBox
              placeholder="Search Treatments"
              query={searchQuery}
              setQuery={setSearchQuery}
              maxWidth={300}
              size="md"
              clearSearchStyle={{
                position: "absolute",
                zIndex: "999",
                top: "7px",
                left: "225px",
                color: "red",
              }}
            />

            {!!selectedTreatments && selectedTreatments.length > 0 && (
              <Button variant="danger" onClick={handleClear}>
                <BsX />
              </Button>
            )}
          </div>
          <ListGroup
            className="scrollbar-thin"
            style={{
              "--bs-list-group-active-bg": "#4b626b",
              "--bs-list-group-active-border-color": "#4b626b",
              maxHeight: 350,
              marginTop: 10,
              overflowY: "auto",
              paddingTop: 10,
              cursor: "pointer",
            }}
          >
            {!!treatments && treatments.length > 0
              ? treatments.map((treatment) => (
                  <ListGroup.Item
                    key={treatment.id}
                    active={selectedTreatments.some(
                      (selected) => selected.id === treatment.id
                    )}
                    onClick={() => handleClick(treatment)}
                  >
                    {treatment.name}
                  </ListGroup.Item>
                ))
              : null}
          </ListGroup>
          {!!headers?.link ? (
            <div
              className="d-flex justify-content-center"
              style={{ paddingTop: 10 }}
            >
              <PaginationFromHeader
                current={page}
                linkStr={headers.link}
                setPage={setPage}
                showButtons
                pageSize={PAGE_SIZE}
              />
            </div>
          ) : null}
        </>
      )}
    />
  );
};

export default TreatmentsListGroup;

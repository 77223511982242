import { useEffect, useState } from "react";
import { config } from "../../config";
import useFetch from "../../utils/useFetch";

const ProgramWrap = ({
  setSelectedProgram,
  selectedProgram,
  component: Component,
}) => {
  const { api } = config;
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 30;
  const [searchQuery, setSearchQuery] = useState("");

  const { data: programs, headers } = useFetch({
    url: `${api.phoenix}/programs?page=${page}&pageSize=${PAGE_SIZE}&query=${searchQuery}`,
  });

  const handleClick = (program) => {
    if (program.id === selectedProgram?.id) {
      setSelectedProgram(null);
    } else {
      setSelectedProgram(program);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  return (
    <Component
      programs={programs}
      handleClick={handleClick}
      page={page}
      setPage={setPage}
      PAGE_SIZE={PAGE_SIZE}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      headers={headers}
      handleClear={() => setSelectedProgram(null)}
    />
  );
};

export default ProgramWrap;

import { useEffect, useState } from "react";
import { config } from "../../config";
import useFetch from "../../utils/useFetch";

const MailMomentsWrap = ({
  selectedProgram,
  selectedMailMoment,
  setSelectedMailMoment,
  component: Component,
}) => {
  const { api } = config;
  const [mailMoments, setMailMoments] = useState(
    !!selectedProgram ? selectedProgram?.mailMoments : []
  );

  const { data: program } = useFetch({
    url: `${api.phoenix}/programs/${selectedProgram.id}`,
  });

  const handleClick = (mailMoment) => {
    if (mailMoment.id === selectedMailMoment?.id) {
      setSelectedMailMoment(null);
    } else {
      setSelectedMailMoment(mailMoment);
    }
  };

  useEffect(() => {
    if (!!program && !!program?.mailMoments && program.mailMoments.length > 0) {
      setMailMoments(program.mailMoments);
    }
  }, [program]);

  return (
    <Component
      mailMoments={mailMoments}
      handleClick={handleClick}
      handleClear={() => setSelectedMailMoment(null)}
    />
  );
};

export default MailMomentsWrap;

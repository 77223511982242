import React from "react";
import { BsBriefcaseFill } from "react-icons/bs";
import FulfilmentPartnerWrap from "./FulfilmentPartnerWrap";

const FulfilmentPartnerBadge = ({ id, customerId }) => {
  return (
    <FulfilmentPartnerWrap
      id={id}
      customerId={customerId}
      component={({ name }) =>
        id ? (
          <span
            className={"fw-semibold cursor-default"}
            title="Fullfilment partner"
          >
            <BsBriefcaseFill className="me-1 opacity-75 fs-90" />
            <span className="fs-75">{name}</span>
          </span>
        ) : (
          <BsBriefcaseFill
            title="Fullfilment partner pending"
            className="text-danger-shade-25 opacity-50"
          />
        )
      }
    ></FulfilmentPartnerWrap>
  );
};

export default FulfilmentPartnerBadge;

import React, { createContext, useContext, useState } from "react";

const LayoutContext = createContext();
export const useLayout = () => useContext(LayoutContext);

export const LayoutProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(window?.innerWidth >= 1200);
  const [hideCollapsed, setHideCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        hideCollapsed,
        setHideCollapsed,
        showSidebar,
        setShowSidebar,
        showMenu,
        setShowMenu,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContext;

import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { helpSectionCategories } from "../../content/helpSection";
import HelpSection from "../custom/HelpSection";
import Layout from "../layout/Layout";
import { Badge, Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const HelpSectionPage = () => {
  return (
    <Layout>
      <div className="dashboard-wrap">
        <h1>Phoenix Tool Knowledge Base</h1>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Dashboard
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Knowledge base</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="mb-3">
          <ListGroup variant="flush">
            {helpSectionCategories.map((item) => (
              <ListGroup.Item
                className="d-flex justify-content-between align-items-start"
                action
                as={Link}
                to={`/help/${item.id}`}
                key={item.id}
              >
                <div className="fw-bold">{item.title}</div>
                <Badge bg="secondary" pill>
                  5
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
        <HelpSection />
      </div>
    </Layout>
  );
};

export default HelpSectionPage;

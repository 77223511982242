import useSWR from "swr";
import { default as defaultFetcher } from "./fetcher";
import { useAuthContext } from "../context/AuthContext";

const useFetch = ({
  url,
  baseURL,
  params,
  clientId: clientIdProp,
  customerId,
  method = "GET",
  payload = null,
  withCredentials = true,
  useActiveClient = true,
  fetcher = defaultFetcher,
  shouldFetch = () => true,
  revalidateIfStale = true,
  revalidateOnFocus = false,
  revalidateOnReconnect = false,
} = {}) => {
  const userContext = useAuthContext();
  const clientId =
    clientIdProp || (useActiveClient ? userContext?.client?.id : null);
  const {
    data: dataWithHeaders,
    error,
    isValidating,
    mutate,
  } = useSWR(
    () =>
      shouldFetch()
        ? {
            url,
            baseURL,
            params,
            clientId,
            customerId,
            method,
            payload,
            withCredentials,
          }
        : null,
    fetcher,
    {
      revalidateIfStale: revalidateIfStale,
      revalidateOnFocus: revalidateOnFocus,
      revalidateOnReconnect: revalidateOnReconnect,
    }
  );
  const { data, headers } = !!dataWithHeaders ? dataWithHeaders : {};
  return {
    data,
    headers,
    error,
    isValidating,
    mutate,
    key: url,
  };
};

export default useFetch;

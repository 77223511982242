import React, { useContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import LayoutContext from "../../context/LayoutContext";

const OutletRow = ({ paramName = "itemId", filterCol, children }) => {
  const [isFinished, setIsFinished] = useState(true);
  const trDuration = 350;
  const params = useParams();
  const itemId = params[paramName];

  const { hideCollapsed, showSidebar, setShowSidebar } =
    useContext(LayoutContext);

  useEffect(() => {
    setIsFinished(false);
    if (itemId) {
      setShowSidebar(false);
      setTimeout(() => {
        setIsFinished(true);
      }, trDuration);
    }
  }, [itemId, setShowSidebar]);

  return (
    <div
      className="outlet-row"
      style={{ "--transition-duration": trDuration / 1000 + "s" }}
    >
      <div className={`outlet-col sidebar-col ${showSidebar ? "isShown" : ""}`}>
        {filterCol}
      </div>
      <div
        className={`outlet-col ${itemId ? "isCollapsed" : "isActive"}${
          hideCollapsed ? " isHidden" : ""
        } ${isFinished ? "finished" : ""}`}
      >
        {children}
      </div>

      <div
        className={`outlet-col ${itemId ? "isActive" : "isCollapsed"} ${
          isFinished ? "finished" : ""
        }`}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default OutletRow;

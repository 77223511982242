import React from "react";
import "./scss/style.scss";
import routes from "./routes";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./context/AuthContext";
import { DrawerProvider } from "./context/DrawerContext";
import { ThemeProvider } from "react-bootstrap";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LayoutProvider } from "./context/LayoutContext";
import { StorageProvider } from "./context/StorageContext";

const router = createBrowserRouter(routes);

const App = () => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <StorageProvider>
          <DrawerProvider>
            <LayoutProvider>
              <ThemeProvider
                breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs"]}
              >
                <RouterProvider router={router} />
              </ThemeProvider>
            </LayoutProvider>
          </DrawerProvider>
        </StorageProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
